import React from 'react';
import PropTypes from 'prop-types'
import GatsbyLink from 'gatsby-link';
import { LocaleContext } from '../components/layout/layout';
import { Container, Section } from '../styles/common/Layout';
import { H1Content, H1, A } from '../styles/common/Typo';
import ContactSection from '../components/Sections/Contact';
import { SEO } from '../components';

const NowPage = ({ data, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO
        title={`${i18n.pageNow} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale} />
      <Section className="section__intro">
        <Container>
          <H1Content>What's Marek doing now?</H1Content>
          <p className="mb-8 leading-relaxed">Last updated April 8th, 2021</p>
          <p className="mb-8 leading-relaxed">
            Inspired by <A title="Derek Sivers Now Page" href="https://sive.rs/nowff" rel="noreferrer"><span>Derek Sivers</span></A>. If you have your own site, <A title="nownownow page" href="http://nownownow.com/about" rel="noreferrer"><span>you should make one too</span></A>.</p>
        </Container>
      </Section>
      <Section className="section__focus" Color="White">
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>I'm focusing on:</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>Almost nothing changend!</li>
                <li>Still putting myself first (more)</li>
                <li>Still trying to keep it together while my entire field is shut down during this pandemic</li>
                <li>Staying sane</li>
                <li>Focussing on my health and fitness and mainly losing weight</li>
                <li>Got vaccinated</li>
                <li>Saying no more</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="section__focus">
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>My location right now:</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>Hattingen, NRW, Germany</li>
                <li>...but working on something bigger!</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="section__focus" Color="White">
        <Container>
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/5">
              <H1>I'm currently working on:</H1>
            </div>
            <div className="w-full md:w-3/5">
              <ul className="list-square list-inside">
                <li>My own blog</li>
                <li>Some great Client Projects</li>
                <li>A few web projects for the heart ❤️</li>
                <li>A new fresh company...</li>
                <li>A, yet to be announced, piece of software/service for the touring/event industry</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <ContactSection />
    </>
  );
};
export default NowPage
